var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[_c('v-toolbar',[_c('v-toolbar-items',{key:_vm.key},[_c('v-btn',{attrs:{"text":""}},[_c('router-link',{class:{
                        active: _vm.strRoute.indexOf('admin-carrousel') >= 0
                    },attrs:{"to":{ name: 'admin-carrousel' }}},[_vm._v("Carrousel")])],1),_c('v-btn',{attrs:{"text":""}},[_c('router-link',{class:{
                        active: _vm.strRoute.indexOf('admin-home') >= 0
                    },attrs:{"to":{ name: 'admin-home' }}},[_vm._v("Home")])],1),_c('v-btn',{attrs:{"text":""}},[_c('router-link',{class:{
                        active: _vm.strRoute.indexOf('admin-banners') >= 0
                    },attrs:{"to":{ name: 'admin-banners' }}},[_vm._v("Banners")])],1),_c('v-btn',{attrs:{"text":""}},[_c('router-link',{class:{
                        active: _vm.strRoute.indexOf('admin-assunto') >= 0
                    },attrs:{"to":{ name: 'admin-assunto' }}},[_vm._v("Assuntos")])],1),_c('v-btn',{attrs:{"text":""}},[_c('router-link',{class:{
                        active: _vm.strRoute.indexOf('admin-tema') >= 0
                    },attrs:{"to":{ name: 'admin-tema' }}},[_vm._v("Temas")])],1),_c('v-btn',{attrs:{"text":""}},[_c('router-link',{class:{
                        active: _vm.strRoute.indexOf('admin-conteudo') >= 0
                    },attrs:{"to":{ name: 'admin-conteudo' }}},[_vm._v("Conteúdos")])],1),_c('v-btn',{attrs:{"text":""}},[_c('router-link',{class:{
                        active: _vm.strRoute.indexOf('admin-evento') >= 0
                    },attrs:{"to":{ name: 'admin-evento' }}},[_vm._v("Eventos")])],1),_c('v-btn',{class:{ active: _vm.strRoute == 'admin-noticia' },attrs:{"text":""}},[_c('router-link',{class:{
                        active: _vm.strRoute.indexOf('admin-noticia') >= 0
                    },attrs:{"to":{ name: 'admin-noticia' }}},[_vm._v("Notícias")])],1),_c('v-btn',{class:{ active: _vm.strRoute == 'admin-videos' },attrs:{"text":""}},[_c('router-link',{class:{
                        active: _vm.strRoute.indexOf('admin-videos') >= 0
                    },attrs:{"to":{ name: 'admin-videos' }}},[_vm._v("Vídeos")])],1),_c('v-btn',{class:{ active: _vm.strRoute == 'admin-categorias' },attrs:{"text":""}},[_c('router-link',{class:{
                        active: _vm.strRoute.indexOf('admin-categorias') >= 0
                    },attrs:{"to":{ name: 'admin-categorias' }}},[_vm._v("Categorias")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }